import React, { useCallback, useEffect, useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { moneyFormat, validaCPF, validarCNPJ } from '~/utils/functions';
import { useFocusTabFinalizacao } from '../../hooks/useFocusTabFinalizacao';
import { usePdvOnline } from '../../hooks/usePdvOnline';
import { pedidoService } from '../../services/pedido';
import { tabFinalizacaoServices } from '../../services/tab-finalizacao';
import { Button } from './styles';
import { tabKeysMappings } from '../../utils/tabKeysMappings';
import { getSaldoCaixa } from '../../services/getSaldoCaixa';

export const ButtonFinalizar: React.FC = () => {
  const [isMounted, setIsMounted] = useState(true);

  const {
    resta,
    finalizadorasTable,
    initialStatePdv,
    produtosTable,
    handleStatusPedido,
    pessoa,
    pedido,
    finalizadorasPedido,
    onOpenModal,
    finalizacaoContainertRef,
    handleActiveTab,
    handleInfoPedido,
    handleSaldoPdv,
  } = usePdvOnline();

  const { handleInputFocus } = useFocusTabFinalizacao();

  const naoComletaVenda =
    resta > 0 ||
    finalizadorasTable.length <= 0 ||
    produtosTable.length <= 0 ||
    pedido.length <= 0;

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  const validateCpfCnpj = useCallback((numero: string) => {
    if (numero === '') return false;

    if (numero.length < 14 || numero.length > 18)
      return 'Número de CPF/CNPJ inválido';

    if (numero.length === 14) {
      if (!validaCPF(numero)) return 'O CPF informado é inválido';
      return false;
    }

    if (numero.length === 18) {
      if (!validarCNPJ(numero)) return 'O CNPJ informado é inválido';
      return false;
    }
  }, []);

  const handleFinalizaVenda = useCallback(async () => {
    if (finalizadorasTable.length === 0) {
      const tabFinalizacao = document.querySelector<HTMLButtonElement>(
        tabKeysMappings[2],
      );
      if (tabFinalizacao) {
        tabFinalizacao.click();
        handleActiveTab('FINALIZACAO');
      }
      toast.warning('Forma de Pagamento deve ser informada');
      return;
    }

    if (naoComletaVenda || !initialStatePdv || !isMounted) return;

    const inputCpfCnpj =
      finalizacaoContainertRef.current?.querySelector<HTMLInputElement>(
        '[name="num_cpf_cnpj"]',
      );

    if (inputCpfCnpj) {
      const numero = inputCpfCnpj.value;
      const validationMessage = validateCpfCnpj(numero || '');

      if (validationMessage) {
        handleInputFocus('num_cpf_cnpj');
        return toast.warning(validationMessage);
      }

      if (numero) {
        const { data } = await tabFinalizacaoServices.getClienteCpfCnpj(
          numero.replace(/[^a-zA-Z0-9]/g, ''),
        );

        if (!data) {
          toast.warning('Número de CPF/CNPJ não encontrado');
          handleInputFocus('num_cpf_cnpj');
          return;
        }
      }
    }

    handleStatusPedido('LANCAMENTO_INCIADO');

    const {
      success,
      data: { cod_seq_pedido, num_pedido, tipo_status },
    } = await pedidoService.createPedido({
      cod_loja: Number(initialStatePdv.cod_loja),
      cod_pessoa: pessoa?.cod_pessoa ?? null,
      num_pdv: Number(initialStatePdv.num_pdv),
      cod_operador: Number(initialStatePdv.cod_operador),
      itemsPedido: pedido,
      finalizadoras: finalizadorasPedido,
    });

    if (success) {
      handleStatusPedido('LANCAMENTO_FINALIZADO');
      onOpenModal('pedidoRegistrado');
      handleInfoPedido(cod_seq_pedido, num_pedido, tipo_status);

      const { saldo } = await getSaldoCaixa(
        Number(initialStatePdv.cod_operador),
        Number(initialStatePdv.num_pdv),
        Number(initialStatePdv.cod_loja),
      );

      handleSaldoPdv(moneyFormat(saldo.toString()));
      return;
    }

    handleStatusPedido('FALHA_LANCAMENTO');
  }, [
    finalizacaoContainertRef,
    finalizadorasPedido,
    finalizadorasTable.length,
    handleActiveTab,
    handleInfoPedido,
    handleInputFocus,
    handleStatusPedido,
    initialStatePdv,
    isMounted,
    naoComletaVenda,
    onOpenModal,
    pedido,
    pessoa?.cod_pessoa,
    validateCpfCnpj,
    handleSaldoPdv,
  ]);

  useEffect(() => {
    const handleKeyFinalizaVenda = async (ev: KeyboardEvent) => {
      if (produtosTable.length > 0 && ev.key === 'F9')
        await handleFinalizaVenda();
    };

    window.addEventListener('keydown', handleKeyFinalizaVenda);
    return () => window.removeEventListener('keydown', handleKeyFinalizaVenda);
  }, [handleFinalizaVenda, produtosTable.length]);

  return (
    <Button
      onClick={handleFinalizaVenda}
      disabled={produtosTable.length <= 0}
      type="button"
    >
      <FaCheck size={23} />
      Finalizar (F9)
    </Button>
  );
};
