import styled from 'styled-components';

export const Button = styled.button<{ openingPdv: boolean }>`
  border: none;
  background-color: transparent;

  position: relative;
  top: ${(props) => (props.openingPdv ? '0.625rem' : '0rem')};

  padding: 0.1875rem;

  &:hover {
    background-color: #d6d6d6;
    border-radius: 0.625rem;
  }
`;
