import React from 'react';
import { AiOutlineFullscreen, AiOutlineFullscreenExit } from 'react-icons/ai';
import { useFullScreen } from '../../hooks/useFullScreen';
import { usePdvOnline } from '../../hooks/usePdvOnline';
import { Tooltip } from '../Tooltip';
import { Button } from './style';

export const ButtonFullScreen: React.FC<{ openingPdv: boolean }> = ({
  openingPdv,
}) => {
  const { isModalOpen, isPdvOpen } = usePdvOnline();
  const { isFullScreen, toggleFullScreen } = useFullScreen();

  const tooltipText = isFullScreen ? 'Modo normal' : 'Modo tela cheia';

  return (
    <Tooltip text={tooltipText} position="left">
      <Button
        onClick={toggleFullScreen}
        type="button"
        aria-label="botão para alternar fullscreen"
        disabled={isPdvOpen && isModalOpen.isOpen}
        openingPdv={openingPdv}
      >
        {!isFullScreen && <AiOutlineFullscreen size={25} />}
        {isFullScreen && <AiOutlineFullscreenExit size={25} />}
      </Button>
    </Tooltip>
  );
};
