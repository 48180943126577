import React from 'react';
import { BsGear } from 'react-icons/bs';
import { useFullScreen } from '../../hooks/useFullScreen';
import { ButtonLink } from './style';

export const ButtonBackHome: React.FC<{ openingPdv: boolean }> = ({
  openingPdv,
}) => {
  const { toggleFullScreen, isFullScreen } = useFullScreen();

  if (openingPdv) return <></>;

  return (
    <ButtonLink onClick={isFullScreen ? toggleFullScreen : undefined} to="/app">
      Voltar
      <BsGear color="white" />
    </ButtonLink>
  );
};
