import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ButtonLink = styled(Link)`
  background-color: #9802fe;
  padding: 0.625rem;
  color: white;
  width: 6.25rem;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  font-size: large;
  font-weight: 600;

  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;

  text-decoration: none;

  &:hover {
    background-color: #7c00d0;
    color: white;
  }

  svg {
    margin-left: 0.3125rem;
  }
`;
