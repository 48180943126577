import React, { useCallback } from 'react';
import { AiOutlineClear } from 'react-icons/ai';
import { useFocusTabProduto } from '../../hooks/useFocusTabProduto';
import { usePdvOnline } from '../../hooks/usePdvOnline';
import { Button } from './styles';
import { resetAbaProdutos } from '../TabProdutos/utils/resetFields';

export const ButtonClearScreen: React.FC = () => {
  const {
    handleResetPdv,
    mainContentRef,
    formTabProduto: { resetTabProduto },
    handleIsIpv,
    handleSelectProduto,
  } = usePdvOnline();

  const { handleInputFocus } = useFocusTabProduto();

  const clearInputLeitor = useCallback(() => {
    const inputLeitor =
      mainContentRef.current?.querySelector<HTMLInputElement>(
        '[name="leitor"]',
      );
    if (inputLeitor) {
      inputLeitor.value = '';
    }
  }, [mainContentRef]);

  const handleTabProdutoClick = useCallback(() => {
    const tabProduto = mainContentRef.current?.querySelector<HTMLButtonElement>(
      '[id="tab-Produtos (Alt + 1)"]',
    );
    if (tabProduto) {
      tabProduto.click();
      handleInputFocus('leitor');
      resetTabProduto(resetAbaProdutos);
    }
  }, [handleInputFocus, mainContentRef, resetTabProduto]);

  const onReset = useCallback(() => {
    if (mainContentRef.current) {
      handleTabProdutoClick();
      handleIsIpv(false);
      handleSelectProduto(null);
      clearInputLeitor();
      handleResetPdv();
    }
  }, [
    clearInputLeitor,
    handleIsIpv,
    handleResetPdv,
    handleSelectProduto,
    handleTabProdutoClick,
    mainContentRef,
  ]);

  return (
    <Button onClick={onReset} type="button">
      <AiOutlineClear size={23} />
      Limpar
    </Button>
  );
};
