import styled from 'styled-components';

export const Button = styled.button`
  border: none;
  padding: 0.625rem;
  border-radius: 0.3125rem;

  text-transform: uppercase;
  font-size: medium;
  color: white;

  background-color: #019f11;

  &:hover {
    background-color: #00860e;
  }

  &:disabled {
    opacity: 60%;
    cursor: not-allowed;
  }

  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
    color: white;
  }
`;
