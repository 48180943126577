import styled from 'styled-components';

export const Button = styled.button`
  border: none;
  padding: 0.625rem;
  border-radius: 0.3125rem;

  text-transform: uppercase;
  font-size: medium;
  color: white;

  background-color: #be9102;

  &:hover {
    background-color: #a98000;
  }

  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
  }
`;
