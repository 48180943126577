import React, { useCallback } from 'react';
import { RiFunctionFill } from 'react-icons/ri';
import { usePdvOnline } from '../../hooks/usePdvOnline';
import { Button } from './styles';

export const ButtonFunctions: React.FC = () => {
  const { handleChangeOpenModalFunction, isModalOpen } = usePdvOnline();

  const handleFunctions = useCallback(() => {
    handleChangeOpenModalFunction(true);
  }, [handleChangeOpenModalFunction]);

  return (
    <Button
      disabled={isModalOpen.isOpen}
      onClick={handleFunctions}
      type="button"
    >
      <RiFunctionFill size={23} />
      Funções (Alt + 0)
    </Button>
  );
};
